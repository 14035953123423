import { useState } from "react";
import { InferGetStaticPropsType } from "next";
import { useRouter } from "next/router";
import Script from "next/script";

import classNames from "classnames";
import { ISbStoryData } from "storyblok-js-client";
import { DynamicBlocks } from "../blocks/DynamicBlock";
import PageTemplate from "../components/PageTemplate";
import { getStaticProps, StoryProvider } from "./[...route]";

const resolveRelations = ["block.source"];

export default function Index({
  isWorkingInStoryblok,
  story: storyProp,
  ...props
}: InferGetStaticPropsType<typeof getStaticProps>): React.ReactElement {
  const router = useRouter();
  const [previewStory, setPreviewStory] = useState<ISbStoryData>();

  const story = previewStory || storyProp;

  function initStoryblokBridge() {
    const storyblokInstance = new window.StoryblokBridge({
      resolveRelations,
    });

    storyblokInstance.on(["change", "published"], () => {
      location.reload();
    });

    storyblokInstance.on("input", (event) => {
      setPreviewStory(event.story);
    });
  }

  return (
    <StoryProvider
      isWorkingInStoryblok={isWorkingInStoryblok}
      story={story}
      {...props}
    >
      <PageTemplate>
        {props.notices?.content.top.content && (
          <div
            className={classNames("Header-notification u-richText", {
              "--bg-black": props.notices?.content.bg_color === "black",
            })}
            dangerouslySetInnerHTML={{
              __html: props.notices?.content.top.content.repeat(10),
            }}
          />
        )}

        <DynamicBlocks blocks={story.content.body} />
      </PageTemplate>

      {isWorkingInStoryblok && router.query._storyblok && (
        <Script
          src="//app.storyblok.com/f/storyblok-v2-latest.js"
          onLoad={initStoryblokBridge}
        />
      )}
    </StoryProvider>
  );
}

export { getStaticProps };
